import Link from "next/link";
import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { BaseApiService } from "@/utils/BaseApiService";
import { useEffect, useState } from "react";
import { isNotEmpty } from "@/utils/Utils";

const UdemyAffiliateBanner = ({ CourseData }) => {

  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    let searchParameters = { searchTerm: "", offset: 0, limit: 3 };
    try {
      const response = await new BaseApiService("/courses").getRequestWithJsonResponse(
        searchParameters
      );
      // console.log("Barner Courses ", response);
      if (response && response.records) {
        setCourses(response.records);
        // console.log("Barner Courses set:", response.records); // Log the state update
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("fetchCourses:fetchDataFromServer>>>>", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);


  return (
    <>
      <Swiper
        className="swiper udemy-affilite-activation rbt-arrow-between"
        slidesPerView={1}
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: ".rbt-arrow-left",
          prevEl: ".rbt-arrow-right",
        }}
        autoplay={{
          delay: 5000, // 3 seconds delay
          disableOnInteraction: false, // Do not disable autoplay on interaction
        }}
      >
        {courses.slice(0, 3).map((data, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-slide">
              <div className="row gy-5 row--30 align-items-center">
                <div className="col-lg-6">
                  <div className="course-thumbnail">
                    <Link href={data.id ? `/course-details/${data.id}` : "#"}>
                      <img
                        style={{width: 710, height: 488}}
                        className="radius-6"
                        src={
                          data.coverImageUrl
                            ? data.coverImageUrl
                            : "https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg"
                        }
                        width={710}
                        height={488}
                        alt="Course Images"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="content">
                    <div className="inner text-start">
                      <div className="section-title">
                        <span className="subtitle bg-secondary-opacity">
                          Recommended Certificate!
                        </span>
                      </div>

                      <div className="rbt-course-meta-wrapper d-flex align-items-center">
                        <div className="rbt-author-meta">
                          <div className="rbt-avater">
                            <Link href={`/profile/${data.id}`}>
                              <img
                                src={
                                  data.instructor && data.instructor.imageUrl
                                    ? data.instructor.imageUrl
                                    : "https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg"
                                }
                                alt={
                                  data.instructor && data.instructor.fullName
                                    ? data.instructor.fullName
                                    : "Instructor"
                                }
                                width={33}
                                height={33}
                              />
                            </Link>
                          </div>
                          <div className="rbt-author-info">
                            By{" "}
                            <Link href={data.instructor && data.instructor.id
                              ? `/profile/${data.instructor.id}`
                              : "#"
                            }>
                              {data.instructor ? data.instructor.fullName : "Instructor"}
                            </Link>{" "}
                            In <Link href="#">{data.category ? data.category.name : "Category"}</Link>
                          </div>
                        </div>

                        <div className="feature-sin rating">
                          <Link href="#">
                            <i className="fa fa-star"></i>
                          </Link>
                          <Link href="#">
                            <i className="fa fa-star"></i>
                          </Link>
                          <Link href="#">
                            <i className="fa fa-star"></i>
                          </Link>
                          <Link href="#">
                            <i className="fa fa-star"></i>
                          </Link>
                          <Link href="#">
                            <i className="fa fa-star"></i>
                          </Link>
                          <Link className="rbt-title-style-2" href="#">
                            (4.8)
                          </Link>
                        </div>
                      </div>

                      <h2 className="banner-title">{data.title}</h2>

                      <p className="description has-medium-font-size mt--20" style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                        {data.description}
                      </p>

                      <div className="slider-btn rbt-button-group justify-content-start">
                        <Link
                          className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y"
                          href={data.id ? `/course-details/${data.id}` : "#"}
                        >
                          <span data-text="ENROLL NOW">ENROLL NOW</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="rbt-swiper-arrow rbt-arrow-left">
          <div className="custom-overfolow">
            <i className="rbt-icon feather-arrow-left"></i>
            <i className="rbt-icon-top feather-arrow-left"></i>
          </div>
        </div>
        <div className="rbt-swiper-arrow rbt-arrow-right">
          <div className="custom-overfolow">
            <i className="rbt-icon feather-arrow-right"></i>
            <i className="rbt-icon-top feather-arrow-right"></i>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default UdemyAffiliateBanner;
