import { useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
import sal from "sal.js";

import CourseData from "../../data/course-details/courseData.json";

import UdemyAffiliateBanner from "./UdemyAffiliate-Banner";
import Card from "../Cards/Card";
import Separator from "../Common/Separator";
import CategoryThree from "../Category/CategoryThree";
import TestimonialTwo from "../Testimonials/Testimonial-Two";
import AboutEight from "../Abouts/About-Eight";

import "venobox/dist/venobox.min.css";


const UdemyAffiliate = () => {
  useEffect(() => {
    sal({
      threshold: 0.01,
      once: true,
    });
  }, []);
  return (
    <>
      <div className="rbt-banner-area rbt-banner-11 bg-color-darker ptb--100">
        <div className="wrapper">
          <div className="container">
            <UdemyAffiliateBanner CourseData={CourseData} />
          </div>
        </div>
      </div>

      <div className="bg-color-white rbt-section-gap" style={{ padding: 50 }}>
        <CallToAction />
      </div>

      <div className="rbt-course-area bg-color-extra2 rbt-section-gap" style={{ padding: 50 }}>
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Featured Courses</h2>
                <p className="description mt--20">
                  At LearningKing, Our training programs are structured to impact individuals enabling them build the right supporting competencies to excel in every sphere of life.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link
                  className="rbt-btn btn-border icon-hover radius-round"
                  href="/courses"
                >
                  <span className="btn-text">Browse LearningKing Courses</span>
                  <span className="btn-icon">
                    <i className="feather-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <Card
              col="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"
              mt=""
              start={0}
              end={12}
              isDesc={false}
              isUser={false}
            />
          </div>

          <div className="row mt--60 text-center">
            <div className="col-lg-12">
              <Link
                className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y ml--30 mr--30"
                href="/courses"
              >
                <span data-text="View More Course">View More Courses</span>
              </Link>

              <Link
                className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y ml--30 mr--30"
                href="/tailor-your-course"
              >
                <span data-text="Tailor Your Course">Tailor Your Course</span>
              </Link>

            </div>
          </div>
        </div>
      </div>

      <div className="rbt-about-area bg-color-darker rbt-section-gap" style={{ padding: 50 }}>
        <div className="container">
          <AboutEight />
        </div>
      </div>

      <div className="rbt-categories-area bg-color-extra2 rbt-section-gap" style={{ padding: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-start">
                <h4 className="title">Popular Categories</h4>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--30">
            <CategoryThree />
          </div>
        </div>
      </div>

      <div className="rbt-testimonial-area bg-color-extra2 mb--30 overflow-hidden">
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--10">
                  <span className="subtitle bg-primary-opacity">
                    FEEDBACK FROM LEARNERS
                  </span>
                  <h2 className="title">
                    What learners say about Learningking training programs.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TestimonialTwo />
      </div>

      <Separator />
    </>
  );
};

const CallToAction = () => {

  const CallToActionData = {
    "collectionOne": [
      {
        "left": [
          {
            "tag": "New Collection",
            "title": "Online and Blended Learning",
            "desc": "Top instructors from around the world",
            "img": "/images/others/video-01.jpg"
          }
        ],
        "right": [
            {
              "tag": "New Collection",
              "title": "Face to face Learning",
              "desc": "Top instructors from around the world",
              "img": "/images/others/video-01.jpg"
            }
        ]
      }
    ]
  };

  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
      });
    });
  }, []);




  return (
    <div className="container">
      {CallToActionData &&
        CallToActionData.collectionOne.map((data, index) => (
          <div className="row g-5" key={index}>
            {data.left.map((item, innerIndex) => (
              <div className="col-lg-6" key={innerIndex}>
                <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-xl-5">
                      <div className="inner">
                        <h4 className="title mb--15">{item.title}</h4>
                        <p className="mb--15">{item.desc}</p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-7">
                      <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20">
                        <Image
                          className="w-100 rbt-radius"
                          src={item.img}
                          width={319}
                          height={229}
                          alt="Video Images"
                        />
                        <Link
                          className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation"
                          data-vbtype="video"
                          href="https://youtu.be/t0UfBRPnyto"
                          controls
                        >
                          <span className="play-icon"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {data.right.map((item, innerIndex) => (
              <div className="col-lg-6" key={innerIndex}>
                <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-xl-5">
                      <div className="inner">
                        <h4 className="title mb--15">{item.title}</h4>
                        <p className="mb--15">{item.desc}</p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-7">
                      <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20">
                        <Image
                          className="w-100 rbt-radius"
                          src={item.img}
                          width={319}
                          height={229}
                          alt="Video Images"
                        />
                        <Link
                          className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation"
                          data-vbtype="video"
                          href="https://youtu.be/VOZnGbICZK4"
                          controls
                        >
                          <span className="play-icon"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};


export default UdemyAffiliate;